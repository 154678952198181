import axios from "axios"

const SERVER_URL = process.env.VUE_APP_SERVER_URL;

class HttpRequest {

    constructor(req) {
        this.req = req;
    }
    request(object) {
        return new Promise((resolve, reject) => {
            let params = Object.assign({}, object, {});
            this.req(params).then(res => {
                var body = res.data;
                var statusCode = res.statusCode;
                if (statusCode >= 400) {
                    reject({
                        statusCode: statusCode,
                        errorCode: statusCode,
                        message: body
                    });
                } else if (body.code && body.code >= 200 && body.code < 400) {
                    resolve(body.data);
                } else if (body.code && body.code >= 400) {
                    reject({
                        errorCode: body.code,
                        message: body.msg || body
                    });
                } else if (body.status && body.status == 'SUCCESS') {       //兼容原来的协议
                    resolve(body.data);
                } else if (body.status == 'ERROR') {
                    reject({
                        errorCode: res.errorCode,
                        message: body.message
                    });
                } else {
                    resolve(body);
                }
            }, reject);
        });
    }

    get({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            params: data,
            headers ,
            method: 'get',
        });
    }

    post({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: "post"
        });
    }

    put({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: 'put'
        })
    }
    del({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: "delete",
        })
    }
}

class OriginHttpRequest {

    constructor(req) {
        this.req = req;
    }
    request(object) {
        return new Promise((resolve, reject) => {
            let params = Object.assign({}, object, {});
            this.req(params).then(res => {
                var body = res.data;
                    var statusCode = res.statusCode;
                    if (statusCode >= 400) {
                        reject({
                            statusCode: statusCode,
                            errorCode: statusCode,
                            message: body
                        });
                    } else {
                        resolve(body);
                    }
            }, reject);
        });
    }

    get({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            params: data,
            headers,
            method: 'get',
        });
    }

    post({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: "post"
        });
    }

    put({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: 'put'
        })
    }
    del({ url, data, headers }) {
        return this.request({
            url: buildRequestUrl(url),
            data,
            headers,
            method: "delete",
        })
    }
}

function buildRequestUrl(url) {
    if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
    } else {
        return SERVER_URL + url;
    }
}

export const http = new HttpRequest(axios.request);
export const originHttp = new OriginHttpRequest(axios.request);

export function httpUrl(url) {
    return buildRequestUrl(url);
}

export function get({ url, data, headers }) {
    return http.get({ url, data, headers });
}

export function post({ url, data, headers }) {
    return http.post({ url, data, headers });
}

export function put({ url, data, headers }) {
    return http.put({ url, data, headers });
}

export function del({ url, data, headers }) {
    return http.del({ url, data, headers });
}

export function oget({ url, data, headers }) {
    return originHttp.get({ url, data, headers });
}

export function opost({ url, data, headers }) {
    return originHttp.post({ url, data, headers });
}

export function oput({ url, data, headers }) {
    return originHttp.put({ url, data, headers });
}

export function odel({ url, data, headers }) {
    return originHttp.del({ url, data, headers });
}
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: "招牌菜五折-餐厅列表" },
    component: () => import('../views/Restaurant.vue')
  },
  {
    path: '/home',
    name: 'Home2',
    meta: { title: "" },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/restaurant',
    name: 'Restaurant',
    meta: { title: "招牌菜五折-餐厅列表" },
    component: () => import('../views/Restaurant.vue')
  },
  {
    path: '/restaurant/:id',
    name: 'RestaurantDetails',
    meta: { title: "招牌菜五折-餐厅详情" },
    component: () => import('../views/RestaurantDetails.vue')
  },
  {
    path: '/restaurant/:id/booking',
    name: 'RestaurantBooking',
    meta: { title: "招牌菜五折-餐厅预定" },
    component: () => import('../views/RestaurantBooking.vue')
  },
  {
    path: '/restaurant-booking-details/:id',
    name: 'RestaurantBookingDetails',
    meta: { title: "招牌菜五折-餐厅预定详情" },
    component: () => import('../views/RestaurantBookingDetails.vue')
  },
  {
    path: '/dish',
    name: 'SpecialtyDish',
    meta: { title: "招牌菜五折-招牌菜" },
    component: () => import('../views/SpecialtyDish.vue')
  },
  {
    path: '/dish/:id',
    name: 'SpecialtyDishDetails',
    meta: { title: "招牌菜五折-招牌菜详情" },
    component: () => import('../views/SpecialtyDishDetails.vue')
  },
  {
    path: '/order/:orderId',
    name: 'OrderGateway',
    meta: { title: "招牌菜五折-订单" },
    component: () => import('../views/OrderGateway.vue')
  },
  // {
  //   path: '/order/:orderId/paying',
  //   name: 'Order',
  //   meta: { title: "招牌菜五折-招牌菜支付" },
  //   component: () => import('../views/Order.vue')
  // },
  {
    path: '/order/:orderId/:payment',
    name: 'OrderPayment',
    meta: { title: "招牌菜五折-招牌菜支付" },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/order/:orderId/card',
    name: 'OrderCardDetails',
    meta: { title: "招牌菜五折-招牌菜订单详情" },
    component: () => import('../views/OrderCardDetails.vue')
  },
  {
    path: '/my',
    name: 'Mine',
    meta: { title: "" },
    component: () => import('../views/my/Mine.vue')
  },
  {
    path: '/my/bookings',
    name: 'MyBookings',
    meta: { title: "" },
    component: () => import('../views/my/MyBookings.vue')
  },
  {
    path: '/my/cards',
    name: 'MyCards',
    meta: { title: "" },
    component: () => import('../views/my/MyCards.vue')
  },
  {
    path: '/card/:cardId',
    name: 'CardDetails',
    meta: { title: "" },
    component: () => import('../views/CardDetails.vue')
  },
  {
    path: '/my/orders',
    name: 'MyOrders',
    meta: { title: "" },
    component: () => import('../views/my/MyOrders.vue')
  },
  {
    path: '/my/orders/:status',
    name: 'MyOrderStatusOrders',
    meta: { title: "" },
    component: () => import('../views/my/MyOrders.vue')
  }, {
    path: '/my/questions',
    name: 'MyQuestions',
    meta: { title: "" },
    component: () => import('../views/my/MyQuestions.vue')
  }, {
    path: '/404',
    name: 'NotFoundPage',
    meta: { title: "" },
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router